
const initState = {
    detailsForm: {
        Community: "",
        SubCommunity: "",
        Vedam: "",
        Country: "",
        Package: "",
        City: "Other",
        Consent: false,
    },
    userData: {
        Name: "",
        Email: "",
        uid: "",
    },
    purchaseDetails: {
        paymentId: "0"
    },
    uuid: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    pricingData: {
        india: {
            actual: {
                offering1: 1001,
                offering2: 621,
                delivery: 50,
                AddAASetPrice: 120,
                AddPoonalPrice: 30,
                AddKPoonalPrice: 20
            },
            earlyBird: {
                offering1: 401,
                offering2: 521,
                delivery: 50,
                AddAASetPrice: 120,
                AddPoonalPrice: 30,
                AddKPoonalPrice: 20
            }
        },
        others: {
            actual: {
                offering1: 40,
                offering2: 18,
                delivery: 37,
                AddAASetPrice: 3,
                AddPoonalPrice: 1,
                AddKPoonalPrice: 1
            },
            earlyBird: {
                offering1: 12,
                offering2: 15,
                delivery: 37,
                AddAASetPrice: 3,
                AddPoonalPrice: 1,
                AddKPoonalPrice: 1
            }
        }
    }
}

export const reducer = (globalState = initState, action) => {
    if(action.type==="SET_DETAILSFORM"){
        return {
            ...globalState,
            detailsForm:action.payload
        }
    }
    if(action.type==="SET_USERDATA"){
        return {
            ...globalState,
            userData:action.payload
        }
    }
    if(action.type==="SET_PURCHASEDETAILS"){
        return {
            ...globalState,
            purchaseDetails:action.payload
        }
    }
    if(action.type==="SET_UUID"){
        return {
            ...globalState,
            uuid:action.payload
        }
    }
    if(action.type==="SET_UTMSOURCE"){
        return {
            ...globalState,
            utm_source:action.payload
        }
    }
    if(action.type==="SET_UTMMEDIUM"){
        return {
            ...globalState,
            utm_medium:action.payload
        }
    }
    if(action.type==="SET_UTMCAMPAIGN"){
        return {
            ...globalState,
            utm_campaign:action.payload
        }
    }
    if(action.type==="SET_PRICINGDATA"){
        return {
            ...globalState,
            pricingData:action.payload
        }
    }
    
    return globalState
}

export default reducer