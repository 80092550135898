const serverUrl = "https://api.nithyakarma.com/"


export default {
    items: {
        "create-user": serverUrl + "create-user/",
        "create-aadetails": serverUrl + "create-aadetails/",
        "create-order": serverUrl + "create-order/",
        "get-all-countries": serverUrl + "get-all-countries/",
        "place-order": serverUrl + "place-order/",
        "send-purchase-summary-email": serverUrl + "send-purchase-summary-email/",
        "get-details-form-data": serverUrl + "get-details-form-data/",
        "get-purchase-details": serverUrl + "get-purchase-details/",
        "get-profile-page-data": serverUrl + "get-profile-page-data/",
        "insert-audio-guid":  serverUrl + "insert-audio-guid/",
        "fetch-audio-guid":  serverUrl + "fetch-audio-guid/",
        "validate-profile-token":  serverUrl + "validate-profile-token/",
        "get-billing-shipping-address": serverUrl + "get-billing-shipping-address/",
        "get-pricing-data": serverUrl + "get-pricing-data/",
        "set-tarpanam-opt-in": serverUrl + "set-tarpanam-opt-in/",
        "get-referral-text": serverUrl + "get-referral-text/",
        "log-speed-adjustment": serverUrl + "log-speed-adjustment/",
    }
};