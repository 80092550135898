import React, { useEffect } from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Switch } from 'react-router-dom';
import './App.css';
import FadeLoader from "react-spinners/FadeLoader";
import lax from 'lax.js'
import { useDispatch } from 'react-redux';
import axios from 'axios';

import apiurls from './_apiurls'


const loading = () => <FadeLoader 
                        css={"position: fixed;top: 50%;left: 50%;"}
                        size={50}
                        color={"orange"}
                      />;

// Containers
const LandingPageComponent = React.lazy(() => import('./containers/LandingPage'));
const DetailsFormComponent = React.lazy(() => import('./containers/DetailsForm'));
const DetailsFormCompleteComponent = React.lazy(() => import('./containers/DetailsFormComplete'));
const AuthenticationComponent = React.lazy(() => import('./containers/Authentication'));
const ForgotPasswordComponent = React.lazy(() => import('./containers/ForgotPassword'));
const PrivacyPolicyComponent = React.lazy(() => import('./containers/PrivacyPolicy'));
const TermsOfServiceComponent = React.lazy(() => import('./containers/TermsOfService'));
const RefundPolicyComponent = React.lazy(() => import('./containers/RefundPolicy'));
const SummaryCartComponent = React.lazy(() => import('./containers/SummaryCart'));
const ProfilePageComponent = React.lazy(() => import('./containers/Profile'));
const ImageCreditsComponent = React.lazy(() => import('./containers/ImageCredits'));
const Page404Component = React.lazy(() => import('./Page404'));

function App(props) {

  const dispatch = useDispatch();

  useEffect(function() {
      window.onload = function() {
          lax.setup() // init
      
          const updateLax = () => {
              lax.update(window.scrollY)
              window.requestAnimationFrame(updateLax)
          }
      
          window.requestAnimationFrame(updateLax)
      }
  }, [])

  useEffect(function() {

    axios.get(apiurls.items['get-pricing-data'])
    .then(res => {
      if(res.data.status === "success"){
        dispatch({type:"SET_PRICINGDATA",payload:res.data.data})
      }
    })

    var utm_source = localStorage.getItem('utm_source');
    if(utm_source === null || utm_source === ""){
      let new_utm_source =  new URLSearchParams(window.location.search).get("utm_source")
      dispatch({type:"SET_UTMSOURCE",payload:new_utm_source})
      localStorage.setItem('utm_source', new_utm_source)
    }else{
      dispatch({type:"SET_UTMSOURCE",payload:utm_source})
      localStorage.setItem('utm_source', utm_source)
    }

    var utm_medium = localStorage.getItem('utm_medium');
    if(utm_medium === null || utm_medium === ""){
      let new_utm_medium =  new URLSearchParams(window.location.search).get("utm_medium")
      dispatch({type:"SET_UTMMEDIUM",payload:new_utm_medium})
      localStorage.setItem('utm_medium', new_utm_medium)
    }else{
      dispatch({type:"SET_UTMMEDIUM",payload:utm_medium})
      localStorage.setItem('utm_medium', utm_medium)
    }

    var utm_campaign = localStorage.getItem('utm_campaign');
    if(utm_campaign === null || utm_campaign === ""){
      let new_utm_campaign =  new URLSearchParams(window.location.search).get("utm_campaign")
      dispatch({type:"SET_UTMCAMPAIGN",payload:new_utm_campaign})
      localStorage.setItem('utm_campaign', new_utm_campaign)
    }else{
      dispatch({type:"SET_UTMCAMPAIGN",payload:utm_campaign})
      localStorage.setItem('utm_campaign', utm_campaign)
    }


    var audioGUID = localStorage.getItem('AudioGUID');
    if (audioGUID !== null && audioGUID !== "") {
      dispatch({type:"SET_UUID",payload:audioGUID})
    }


    var userData = localStorage.getItem('UserData');
    if (userData != null) {
      dispatch({type:"SET_USERDATA",payload:JSON.parse(userData)})
    } else {

    }

    var detailsForm = localStorage.getItem('DetailsForm');
    if (detailsForm != null) {
      dispatch({type:"SET_DETAILSFORM",payload:JSON.parse(detailsForm)})
    } else {
      if(userData != null){
        axios.post(apiurls.items["get-details-form-data"], userData)
        .then(res => {

          let resDetailsFormData = JSON.parse(res.data)

          if(resDetailsFormData === "Something went wrong"){
            let detailsFormReducer = {
              Community: "",
              SubCommunity: "",
              Vedam: "",
              Country: "",
              Package: "",
              City: "Other"
            }
            localStorage.setItem('DetailsForm', detailsFormReducer)
          }else{
            if(resDetailsFormData.Vedam !== ""){
              dispatch({type:"SET_DETAILSFORM",payload:resDetailsFormData})
              localStorage.setItem('DetailsForm', res.data)
            }
          }
        })
        .catch((error) => {

        });
      }
    }

    var purchaseDetails = localStorage.getItem('PurchaseDetails');
    if (purchaseDetails != null) {
      dispatch({type:"SET_PURCHASEDETAILS",payload:JSON.parse(purchaseDetails)})
    } else {
      if(userData != null){
        axios.post(apiurls.items["get-purchase-details"], userData)
        .then(res => {

          let resPurchaseData = JSON.parse(res.data)

          if(resPurchaseData.paymentId !== "0"){
            dispatch({type:"SET_PURCHASEDETAILS",payload:resPurchaseData})
            localStorage.setItem('PurchaseDetails', res.data)
          }
        })
        .catch((error) => {

        });
      }
    }
  // eslint-disable-next-line
  }, [])

  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/" name="Home" render={props => <LandingPageComponent {...props}/>} />
          <Route exact path="/auth" name="Authentication" render={props => <AuthenticationComponent {...props}/>} />
          <Route exact path="/forgot-password" name="Password Reset" render={props => <ForgotPasswordComponent {...props}/>} />
          <Route exact path="/privacy-policy" name="Privacy Policy" render={props => <PrivacyPolicyComponent {...props}/>} />
          <Route exact path="/tos" name="Terms Of Service" render={props => <TermsOfServiceComponent {...props}/>} />
          <Route exact path="/refund-policy" name="Refund Policy" render={props => <RefundPolicyComponent {...props}/>} />
          <Route exact path="/avani-avittam-details" name="Details Form" render={props => <DetailsFormCompleteComponent {...props}/>} />
          <Route exact path="/mahalaya-paksha-tarpanam-details" name="Details Form" render={props => <DetailsFormComponent {...props}/>} />
          <Route exact path="/cart-summary" name="Summary Cart" render={props => <SummaryCartComponent {...props}/>} />
          <Route exact path="/profile" name="Profile Page" render={props => <ProfilePageComponent {...props}/>} />
          <Route exact path="/profile/:userId/:token" name="Profile Page" render={props => <ProfilePageComponent {...props}/>} />
          <Route exact path="/image-credits" name="Image Credits" render={props => <ImageCreditsComponent {...props}/>} />
          <Route exact path="/error" name="Page 404" render={props => <Page404Component {...props}/>} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
